<template>
  <div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Nom</label>
        <input type="text" v-model="supplier.lastName" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Prénom</label>
        <input type="text" v-model="supplier.firstName" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Téléphone</label>
        <input type="text" v-model="supplier.phone" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">E-mail</label>
        <input type="text" v-model="supplier.email" class="form-control" />
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Nom Comerciale</label>
        <input type="text" v-model="supplier.society" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Ville</label>
        <input type="text" v-model="supplier.city" class="form-control" />
      </div>

      <div class="col-6">
        <label for="">Adresse</label>
        <input type="text" v-model="supplier.address" class="form-control" />
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-3">
        <label for="">I.C.E</label>
        <input type="text" v-model="supplier.ice" class="form-control" />
      </div>

      <div class="col-6">
        <label for="">R.I.B</label>
        <input type="text" v-model="supplier.rib" class="form-control" />
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-12">
        <label for="">Remarque</label>
        <textarea v-model="supplier.remark" class="form-control"></textarea>
      </div>
    </div>

    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(supplier)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // supplier: {},
    };
  },
  computed: {
    ...mapGetters("supplier", {
      supplier: "getSupplier",
    }),
  },
  methods: {
    async save(data) {
      let supplier = data;
      await this.$confirm({
        message:
          "Vous voulez Modifier le fournisseur  " +
          supplier.fullName +
          " " +
          +supplier.society,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("supplier/update", supplier);
          }
        },
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("supplier/show", this.$route.params.reference);
  },
};
</script>
